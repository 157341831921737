@import "jose_modern/css";
@import "jose_modern/css/pricing_mixins";
/* This pricing table can be configured here.  Here are the variables we have access to before
   importing:
   https://github.com/Wikiki/bulma-pricingtable/blob/master/src/sass/index.sass
*/
@import "~bulma-pricingtable/src/sass";

@mixin has-blob($variant: "blob-1") {
  background: url("~pages/home/images/#{$variant}.svg") no-repeat scroll top center;
  background-size: contain;
}

html[data-view="pages#pricing"],
html[data-view="pages#expert"],
html[data-view="pages#inventory_pricing"],
html[data-view="pages#personal_nutrition_pricing"] {
  section.pricing-nav {
    padding-bottom: 0;
  }

  .price-section-header {
    padding-top: 48px;
    padding-bottom: 48px;
    background-color: $white-ter;
  }

  .price-section {
    padding-top: 1.5rem;
    padding-bottom: 10rem;
    margin-bottom: 5rem;
    background-color: $white-ter;
    border-bottom: 2px solid $grey-lighter;
  }

  #still-have-questions {
    @include has-blob("blob-5");
    background-position-y: 50%;
    background-size: 100% 200%;
  }

  .pricing-table {
    justify-content: center;

    .plan-item .content,
    .plan-item .long {
      display: inline-block;
      max-width: 20em;
    }

    .pricing-plan li {
      margin-bottom: 0.75rem;
    }

    .pricing-plan.is-active .plan-price .plan-price-amount {
      color: $black;
    }

    .pricing-plan {
      position: relative;
      padding: 2em 0.5em;
      margin: 0.5em 1em;
      box-shadow: $box-shadow;

      /* the bouncy effect actually looks weird on slower-than-instant loading */
      transition: none;

      @include touch {
        /* disable the automatic scale is-active plan */
        &.is-active {
          transform: scale(1);
          box-shadow: $box-shadow;
        }
      }

      @include tablet {
        max-width: 25em;
      }

      .tag {
        background-color: $secondary;
        color: $white;
        width: auto;
        position: absolute;
        right: 2em;
        font-size: $size-7;
        font-weight: bold;
      }

      a.button {
        font-weight: bold;
        font-size: $size-6;
      }

      .plan-header {
        text-align: left;
        margin-top: 2rem;
        font-family: $family-secondary;
      }

      .plan-subtitle {
        color: $grey;
        font-size: $size-6;
        margin-top: -1rem;
        margin-left: 1em;
      }

      .plan-per {
        margin-left: 1em;
        margin-top: -0.25em;
        color: $grey;
        font-size: $size-6;
      }

      .plan-price {
        text-align: left;
        .plan-price-amount {
          color: $black;
          font-weight: 900;
          font-size: $size-1;
          .plan-price-currency {
            color: $secondary;

            &:last-child {
              color: $grey;
              margin-left: 0.2em;
              vertical-align: baseline;
            }
          }
        }
      }

      .plan-items .plan-item {
        background-color: $white;
        color: $black;
        text-align: left;

        display: flex;

        padding: 0.5em 0.75em;

        .icon {
          margin-right: 0.5em;
          color: $success;
        }

        span {
          small {
            color: $grey;
            font-style: italic;
          }
        }
      }

      .plan-footer {
        background-color: $white-bis;
        margin-top: 2em;
        margin-bottom: -36px;
        margin-left: -8px;
        margin-right: -10px;
      }
    }

    &.monthly {
      .annual {
        display: none;
      }
    }
    &.annual {
      .monthly {
        display: none;
      }
    }
  }
}

.satisfaction-badge {
  @include touch {
    display: block;
    text-align: center;

    img {
      padding-bottom: map-get($map: $spacing-values, $key: "6");
    }
  }

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: map-get($map: $spacing-values, $key: "5");
    }
  }
}

#billing-toggle {
  --toggle-padding: 4px;
  --active-color: #222;

  position: relative;
  display: inline-block;
  background: #e3e8ed;
  padding: var(--toggle-padding);
  border-radius: 50px;
  border: 1px solid #d0d8e0;
  box-shadow:
    inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;

  .toggle-radio {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked ~ .toggle-container {
      .slider {
        transform: translateX(calc(100% + (var(--toggle-padding) * 2)));
      }

      .toggle-option[for="annual"] {
        color: var(--active-color);
      }
    }

    &#monthly:checked ~ .toggle-container {
      .slider {
        transform: translateX(0);
      }

      .toggle-option[for="monthly"] {
        color: var(--active-color);
      }
    }

    &:active ~ .toggle-container .slider {
      transform: translateX(calc(var(--toggle-padding) * -1));
      transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:active:checked ~ .toggle-container .slider {
      transform: translateX(calc(100% + (var(--toggle-padding) * 3)));
    }
  }

  .toggle-container {
    position: relative;
    display: flex;
    height: 48px;

    .toggle-option {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      padding: 0 32px;
      color: $grey;
      font-weight: 500;
      cursor: pointer;
      transition: color 0.3s ease;
      z-index: 1;

      &:hover {
        color: var(--active-color);

        .discount-badge {
          filter: none !important;
          color: #fff !important;
        }
      }

      &:active {
        transform: scale(0.98);
        transition: transform 0.1s ease;
      }
    }

    .slider {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50% - var(--toggle-padding));
      height: 100%;
      background: $white;
      border-radius: 50px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .discount-badge {
      @include discount-badge-base;

      top: -14px;
      right: -5px;
      padding: 4px 12px;
      font-size: 0.75rem;
      line-height: 1;

      animation: pricingBadgeGrowRotate 0.7s cubic-bezier(0.34, 1.56, 0.64, 1);
      animation-fill-mode: forwards;
      transform: scale(1);
      pointer-events: none;

      .text-content {
        animation: textTransition 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
      }
    }
  }
}
